import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function Footer() {
  return (
    <>
   <div className='bg-primary p-5 fixed-bottom'>
    <Container>
    <Row>
        <Col md={4} className='text-white'>
        </Col>
        <Col md={4}>2</Col>
        <Col md={4}>3</Col>

     </Row>
    </Container>
   </div>
    </>
  )
}

export default Footer