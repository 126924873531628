import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Home() {
  return (
    <>
    <Header></Header>


    <Footer></Footer>
    </>
  )
}

export default Home