import React from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap'
import { FaHome, FaPhoneAlt,FaUsers } from 'react-icons/fa'

function Header() {
  return (
    
    <Navbar expand="lg" className='bg-white shadow p-3'>
        <Container>
            <Navbar.Brand href='/' >Demo Project</Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-nav-navbar'></Navbar.Toggle>
            <Navbar.Collapse id='basic-nav-navbar'>
            <Nav className='mx-auto text-uppercase'>
            <Nav.Link href='/'><FaHome/>  Home</Nav.Link>
            <Nav.Link href='/about'><FaUsers/> About Us</Nav.Link>
            <Nav.Link href='/contact'><FaPhoneAlt/> Contact Us</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>



  )
}

export default Header