import './App.css';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <>
    <Outlet></Outlet>
    
    
    </>
  );
}

export default App;
